import React, { lazy, useEffect, useState } from "react";

import { Autocomplete, Box, Button, Paper, TextField } from "@mui/material";
import { FiPlus } from "react-icons/fi";
import {
  AddCustomer,
  CatchError,
  getAllProvince,
  getCities,
} from "../../../setup/services";
import Swal from "sweetalert2";
import Title from "components/Title";
import useAuth from "hooks/useAuth";
import ButtonLoading from "components/ButtonLoading";
import { useTranslation } from "react-i18next";
const CustomBox = lazy(() => import("components/CustomBox"));

const Ajouter = ({ isDialog, setIsInsert }) => {
  const [t, i18n] = useTranslation("global");
  const { auth } = useAuth();
  const [Provinces, setProvinces] = useState([]);
  const [Cities, setCities] = useState([]);
  const [loading, setLoaded] = useState(false);
  const [Province, setProvince] = React.useState(null);
  const [City, setCity] = React.useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    id_province: "",
    id_city: "",
  });
  const { name, address, phone, email } = formData;
  const [ProvinceSelected, setProvinceSelected] = React.useState(true);
  const defaultProps = {
    options: Provinces,
    getOptionLabel: (option) =>
      i18n.language === "ar" ? option.ar_name_province : option.name_province,
  };
  const defaultProps1 = {
    options: Cities,
    getOptionLabel: (option) =>
      i18n.language === "ar" ? option.ar_name_city : option.name_city,
  };
  useEffect(() => {
    if (auth.branch !== undefined) {
      getAllProvince(auth.branch).then((data) => {
        setProvinces(data);
      });
    }
  }, [auth.branch]);
  useEffect(() => {
    if (Province !== null && auth.branch !== undefined) {
      getCities(Province.id_province, Province.country_id).then((data) => {
        setCities(data);
        setProvinceSelected(false);
      });
    } else {
      setProvinceSelected(true);
    }
  }, [Province]);

  const SendValue = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const SendForm = (e) => {
    e.preventDefault();
    setLoaded(true);
    setFormErrors({
      name: "",
      email: "",
      phone: "",
      id_province: "",
      id_city: "",
    });
    AddCustomer(
      name,
      address,
      phone,
      City && City.id_city ? City.id_city : null,
      Province && Province.id_province ? Province.id_province : null,
      email,
      auth.branch
    )
      .then((response) => {
        if (response.status === 201) {
          MessageValidate();
          if (isDialog) setIsInsert((prev) => !prev);
          setFormData({
            name: "",
            phone: "",
            email: "",
            address: "",
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.errors?.length > 0) {
          error.response.data.errors.forEach((objet) => {
            if (formErrors.hasOwnProperty(objet.path)) {
              setFormErrors((prev) => ({
                ...prev,
                [objet.path]: objet.msg,
              }));
            }
          });
        } else {
          CatchError();
        }
      })
      .finally(() => {
        setLoaded(false);
      });
  };
  const MessageValidate = () => {
    Swal.fire({
      title: "Valider!",
      text: t("Le client a été bien ajouté"),
      icon: "success",
      showConfirmButton: true,
      confirmButtonColor: "#2399baff",
      timer: 2500,
    });
  };
  return (
    <Box width={"75%"} marginX={"auto"}>
      <Title title={t("Ajouter un client")} />
      {!isDialog && (
        <CustomBox
          title={t("Ajouter un client")}
          parent={t("Clients")}
          url={"clients"}
        />
      )}

      <Box component={Paper} elevation={3} padding={2} borderRadius={2}>
        <form action="" className="form" onSubmit={(e) => SendForm(e)}>
          <TextField
            focused
            error={formErrors.name === "" ? false : true}
            helperText={formErrors.name === "" ? false : formErrors.name}
            id="name"
            label={t("Client")}
            className="label_txtField"
            variant="outlined"
            onChange={(e) => SendValue(e)}
            value={name}
          />
          <TextField
            error={formErrors.phone === "" ? false : true}
            helperText={formErrors.phone === "" ? false : formErrors.phone}
            id="phone"
            label={t("Téléphone")}
            variant="outlined"
            value={phone}
            className="label_txtField"
            onChange={(e) => SendValue(e)}
          />
          <TextField
            error={formErrors.email === "" ? false : true}
            helperText={formErrors.email === "" ? false : formErrors.email}
            id="email"
            value={email}
            label={`${t("Email")}/ ${t("Téléphone")} 2`}
            className="label_txtField"
            onChange={(e) => SendValue(e)}
            variant="outlined"
          />

          <div className="select-d-flex">
            <Autocomplete
              className="auto-complete"
              {...defaultProps}
              id="wilaya"
              clearOnEscape
              error={true}
              onChange={(event, newValue) => {
                setProvince(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  error={formErrors.id_province === "" ? false : true}
                  helperText={
                    formErrors.id_province === ""
                      ? false
                      : formErrors.id_province
                  }
                  {...params}
                  label={t("Wilaya")}
                  variant="outlined"
                />
              )}
            />
            <Autocomplete
              className="auto-complete"
              {...defaultProps1}
              id="commune"
              clearOnEscape
              onChange={(event, newValue) => {
                setCity(newValue);
              }}
              disabled={ProvinceSelected}
              renderInput={(params) => (
                <TextField
                  error={formErrors.id_city === "" ? false : true}
                  helperText={
                    formErrors.id_city === "" ? false : formErrors.id_city
                  }
                  {...params}
                  label={t("Commune")}
                  variant="outlined"
                />
              )}
            />
          </div>
          <TextField
            id="address"
            className="label_txtField"
            label={t("Adresse")}
            variant="outlined"
            onChange={(e) => SendValue(e)}
          />
          <div className="btn-add_produit">
            {loading ? (
              <ButtonLoading />
            ) : (
              <Button
                variant="contained"
                type="submit"
                style={{ marginLeft: 8 }}
                startIcon={<FiPlus />}
              >
                {t("Ajouter")}
              </Button>
            )}
          </div>
        </form>
      </Box>
    </Box>
  );
};

export default Ajouter;
