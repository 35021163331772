import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import ButtonLoaded from "components/ButtonLoaded";
import { useState } from "react";
const DialogShop = ({
  handleClose,
  open,
  sendDomaine,
  loaded,
  success,
  domaine,
  setDomaine,
  t,
}) => {
  function isShopifyDomain(input) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com$/;
    return regex.test(input);
  }

  const [ErrorDomaine, setErrorDomaine] = useState("");
  const handleError = (domaine) => {
    let hasError = false;
    if (domaine === "") {
      setErrorDomaine(t("Vous devez ajouter un domaine de boutique"));
      hasError = true;
    }
    if (!isShopifyDomain(domaine)) {
      setErrorDomaine(t("Domaine Shopify invalide"));
      hasError = true;
    }
    return hasError;
  };
  const CheckDomaine = () => {
    setErrorDomaine("");
    if (!handleError(domaine)) {
      sendDomaine();
    }
  };
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle color={"#01579b"} fontWeight={"600"}>
        {t("Se connecter à Shopify Boutique")}
      </DialogTitle>

      <DialogContent>
        <Box
          noValidate
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <TextField
            sx={{ minWidth: 350 }}
            error={ErrorDomaine === "" ? false : true}
            helperText={ErrorDomaine === "" ? false : ErrorDomaine}
            onChange={(e) => setDomaine(e.target.value)}
            variant="outlined"
            placeholder="exemple.myshopify.com"
            className="auto-complete"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          style={{ textTransform: "none" }}
          onClick={handleClose}
        >
          {t("Annuler")}
        </Button>
        <ButtonLoaded
          success={success}
          loading={loaded}
          handleButtonClick={CheckDomaine}
          title={t("Valider")}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogShop;
