import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import ButtonLoaded from "components/ButtonLoaded";
import React, { useState } from "react";
import { CatchError, ToastStatus, UpdateShopApiElement } from "setup/services";
import { toast } from "react-toastify";
const steps = ["Choisir Un Boutique", "Saisir Youcan Information"];

const DialogShop = ({
  handleClose,
  open,
  email,
  password,
  setEmail,
  setPassword,
  shops,
  setDomaines,
  branch,
  t,
}) => {
  // const URL_WEBHOOK = `${process.env.REACT_APP_API_URL}/shopify/webhook/orders_create`;
  const [ErrorEmail, setErrorEmail] = useState("");
  const [ErrorPassword, setErrorPassword] = useState("");
  const [errorShop, setErorShop] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [shopSelected, setShopSelected] = useState(null);
  const [loadedShop, setLoadedShop] = useState(false);
  const [success, setSuccess] = useState(false);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (shopSelected === null) {
      setErorShop(t("Ce champs boutique est requis"));
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleError = () => {
    let hasError = false;
    if (email === "") {
      setErrorEmail(t("Vous devez ajouter un email"));
      hasError = true;
    }
    if (password === "") {
      setErrorPassword(t("Vous devez ajouter un mot de passe"));
      hasError = true;
    }
    if (shopSelected === null) {
      setErorShop(t("Ce champs boutique est requis"));
      hasError = true;
    }

    return hasError;
  };
  const CheckDomaine = () => {
    setErrorEmail("");
    setErrorPassword("");
    setErorShop("");
    if (!handleError()) {
      sendDomaine();
    }
  };
  const AffecterMessage = () => {
    toast.success(t("L'affectation a été bien valider !"), {
      ...ToastStatus,
    });
  };
  const sendDomaine = () => {
    setLoadedShop(true);
    const data = {
      email_youcan: email,
      password_youcan: password,
      platforme: "youcan",
      shop: shopSelected?.id_shop,
      branch,
      type: 3,
    };

    UpdateShopApiElement(data)
      .then((resp) => {
        if (resp.data?.length > 0) {
          setDomaines(resp.data);
        }
        AffecterMessage();
      })
      .catch(() => {
        CatchError();
      })
      .finally(() => {
        setLoadedShop(false);
      });
  };
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle color={"#01579b"} fontWeight={"600"}>
        {t("Se connecter à Youcan Boutique")}
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Réinitialiser</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Autocomplete
                      options={shops || []}
                      getOptionLabel={(option) =>
                        option ? option.name_shop : ""
                      }
                      className="auto-complete"
                      isOptionEqualToValue={(option, value) =>
                        option.id_shop === value.id_shop
                      }
                      sx={{ width: "320px" }}
                      value={shopSelected}
                      onChange={(event, newValue) => {
                        setShopSelected(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={errorShop === "" ? false : true}
                          helperText={errorShop === "" ? false : errorShop}
                          {...params}
                          label={t("Boutiques")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      m: "auto",
                      width: "fit-content",
                    }}
                  >
                    <TextField
                      sx={{ minWidth: "70%" }}
                      error={ErrorEmail === "" ? false : true}
                      helperText={ErrorEmail === "" ? false : ErrorEmail}
                      onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      placeholder="exemple@gmail.com"
                      className="auto-complete"
                    />
                    <TextField
                      sx={{ minWidth: "70%" }}
                      error={ErrorPassword === "" ? false : true}
                      helperText={ErrorPassword === "" ? false : ErrorPassword}
                      onChange={(e) => setPassword(e.target.value)}
                      variant="outlined"
                      type="password"
                      placeholder={t("Mot de passe")}
                      className="auto-complete"
                    />
                  </Box>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("Arrière")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  {activeStep === steps.length - 1 ? (
                    <ButtonLoaded
                      success={success}
                      loading={loadedShop}
                      handleButtonClick={CheckDomaine}
                      title={"Terminée"}
                    />
                  ) : (
                    <Button onClick={handleNext}>{t("Suivant")}</Button>
                  )}
                </Box>
                {/* ALSHAHED ABAYA42 */}
              </React.Fragment>
            )}
          </Box>
        </Box>
      </DialogContent>
      {/* <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          size="small"
          style={{ textTransform: "none" }}
          onClick={handleClose}
        >
          Annuler
        </Button>
        
      </DialogActions> */}
    </Dialog>
  );
};

export default DialogShop;
