import "./App.css";
import Main from "./pages/Main/Main";
import React, { createContext, lazy, Suspense, useState } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import useAuth from "./hooks/useAuth";
import AjouterCommande from "./pages/Command/Ajouter/AjouterCommande";
import ListeSheet from "./pages/Command/Sheet/ListeSheet";
import ListeYoucan from "pages/Command/Youcan/ListeYoucan";
import ListeShopify from "pages/Command/Shopify/ListeShopify";
import ImporterCommandeExcel from "pages/Command/Excel/ListeExcel";
import WoocommerceListe from "pages/Command/Woocommerce/WoocommerceListe";
import LightfunnelsList from "pages/Command/LightFunnels/LightfunnelsList";
import enTranslations from "./translate/en/translation.json";
import frTranslations from "./translate/fr/translation.json";
import arTranslations from "./translate/ar/translation.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "fr",
  resources: {
    en: {
      global: enTranslations,
    },
    fr: {
      global: frTranslations,
    },
    ar: {
      global: arTranslations,
    },
  },
});
const Ajouter = lazy(() => import("./pages/Client/Ajouter/Ajouter"));
const Liste = lazy(() => import("./pages/Client/Liste/Liste"));
const Modifier = lazy(() => import("./pages/Client/Modifier/Modifier"));
const Import = lazy(() => import("./pages/Client/Import/Import"));
const AjouterArticle = lazy(() =>
  import("./pages/article/Ajouter/AjouterArticle")
);
const ListeArticle = lazy(() => import("./pages/article/Liste/ListeArticle"));
const ModifierArticle = lazy(() =>
  import("./pages/article/Modifier/ModifierArticle")
);
const Trafic = lazy(() => import("./pages/stock/Trafic/Trafic"));
const AjouterFournisseur = lazy(() =>
  import("./pages/Fournisseur/Ajouter/AjouterFournisseur")
);
const ListeFournisseur = lazy(() => import("./pages/Fournisseur/Liste/Liste"));
const AjouterMarque = lazy(() =>
  import("./pages/Marque/Ajouter/AjouterMarque")
);
const ListeMarque = lazy(() => import("./pages/Marque/Liste/ListeBrand"));
const AjouterLivraison = lazy(() =>
  import("./pages/Livraison/Ajouter/AjouterLivraison")
);
const ListeLivreur = lazy(() =>
  import("./pages/Livraison/Liste/ListeLivreurs")
);
const FraisLivraison = lazy(() =>
  import("./pages/Livraison/Frais/FraisLivraison")
);
const ModifierLivraison = lazy(() =>
  import("./pages/Livraison/Modifier/ModifieLivraison")
);

// const AjouterCommande = lazy(() =>
//   import("")
// );
const ListeConfirmation = lazy(() =>
  import("./pages/Command/Liste/ListeConfirmation")
);
const ListePreparation = lazy(() =>
  import("./pages/Command/Liste/ListePreparation")
);
const ListeDispatch = lazy(() => import("./pages/Command/Liste/ListeDispatch"));
const ListeLivraison = lazy(() =>
  import("./pages/Command/Liste/ListeLivraison")
);
const ListeAnnuler = lazy(() => import("./pages/Command/Liste/ListeAnnuler"));
const StatutsConfirmation = lazy(() =>
  import("./pages/Status/StatutsConfirmation")
);
const StatutsLivraison = lazy(() => import("./pages/Status/StatutsLivraison"));
const AjouterBoutique = lazy(() =>
  import("./pages/Boutique/Ajouter/AjouterBoutique")
);
const ListeBoutiques = lazy(() =>
  import("./pages/Boutique/Liste/ListeBoutiques")
);

const Inventaire = lazy(() => import("./pages/stock/Inventaire/Inventaire"));
const AjouterModerateur = lazy(() =>
  import("./pages/Moderateur/Ajouter/Ajouter")
);
const Login = lazy(() => import("./pages/Login/Login"));
const RoutLayout = lazy(() => import("./layouts/RootLayout"));
const IndexLayout = lazy(() => import("./layouts/IndexLayout"));
const NotFound = lazy(() => import("./pages/404/404"));
const RequireAuth = lazy(() => import("./components/RequireAuth"));
const AjouterRole = lazy(() => import("./pages/Role/Ajouter/AjouterRole"));
const ListeLivree = lazy(() => import("./pages/Command/Liste/ListeLivree"));
const ListeRetour = lazy(() => import("./pages/Command/Liste/ListeRetour"));

const Paiement = lazy(() => import("./pages/bons/Paiement"));
const Retour = lazy(() => import("./pages/bons/Retour"));
const TypesLayout = lazy(() => import("./layouts/TypesLayout"));
const UnAuthorized = lazy(() => import("./pages/UnAuthorized/UnAuthorized"));
const OptionsArticle = lazy(() =>
  import("pages/article/Options/OptionsArticle")
);
const ListeUsers = lazy(() => import("pages/Moderateur/Liste/Liste"));
const ModifierModerateur = lazy(() =>
  import("pages/Moderateur/Modifier/Modifier")
);
const ListeRole = lazy(() => import("pages/Role/Liste/ListeRole"));
const ModifierRole = lazy(() => import("pages/Role/Modifier/ModifierRole"));
const AlerteStock = lazy(() => import("pages/stock/Alerte/AlerteStock"));

const StatutsGeneraux = lazy(() => import("pages/Status/StatutsGeneraux"));
const TypesCharges = lazy(() => import("pages/Comptabilite/TypesCharges"));
const Charges = lazy(() => import("pages/Comptabilite/Charges"));
const Bilan = lazy(() => import("pages/Comptabilite/Bilan"));
const HistoriqueCmd = lazy(() =>
  import("pages/Command/Historique/HistoriqueCmd")
);
const ModifierFournisseur = lazy(() =>
  import("pages/Fournisseur/Modifier/ModifierFournisseur")
);
const ModifierMarque = lazy(() =>
  import("pages/Marque/Modifier/ModifierMarque")
);
const ModifierBoutique = lazy(() =>
  import("pages/Boutique/Modifier/ModifierBoutique")
);

const Commande = lazy(() => import("pages/Statistique/Commande"));
const Produit = lazy(() => import("pages/Statistique/Produit"));
const Livraison = lazy(() => import("pages/Statistique/Livraison"));
const Confirmation = lazy(() => import("pages/Statistique/Confirmation"));
const Profile = lazy(() => import("pages/Moderateur/Profile"));
const AuthMain = lazy(() => import("components/AuthMain"));

const ImportArticle = lazy(() => import("pages/article/Import/ImportArticle"));
const ImportTrafic = lazy(() => import("pages/stock/Import/ImportStock"));
const Setting = lazy(() => import("pages/setting/Setting"));
const MainUser = lazy(() => import("pages/Main/MainUser"));
const AjouterBranche = lazy(() =>
  import("pages/Branche/Ajouter/AjouterBranche")
);
const ModifierBranche = lazy(() =>
  import("pages/Branche/Modifier/ModifierBranche")
);
const ListeBranches = lazy(() => import("pages/Branche/Liste/ListeBranches"));
const Meta = lazy(() => import("pages/meta/Meta"));
const Tiktok = lazy(() => import("pages/tiktok/Tiktok"));

export const TypeContext = createContext();
function App() {
  const { auth } = useAuth();

  const [changeType, setChangeType] = useState(false);
  // const [changeTypePickup, setChangeTypePickup] = useState("test");

  //   refreshLogin()
  //     .then((response) => {
  //       if (response.status === 201) {
  //         const data = response.data;
  //         setAuth({
  //           loading: false,
  //           email: data.email,
  //           username: data.username,
  //           idUser: data.idUser,
  //           isAuth: true,
  //           accessToken: data.accessToken,
  //           permissions: data.permissions,
  //           role: data.role_id,
  //           devise: data.devise,
  //           branch: data.id_branch,
  //           name_branch: data.name_branch,
  //         });
  //       } else {
  //         // Gestion de l'erreur - redirection vers la page de connexion, etc.
  //         // navigateToLogin("/auth");
  //         setAuth({ loading: false });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "Erreur lors de la récupération du nouveau jeton d'accès",
  //         error
  //       );
  //       setAuth({ loading: false });
  //     });
  // };
  // useEffect(() => {
  //   const fragmentsToCheck = [
  //     "commandes/0/lists",
  //     "commandes/1/lists",
  //     "commandes/2/lists",
  //     "commandes/3/lists",
  //     "commandes/4/lists",
  //     "commandes/5/lists",
  //     "commandes/6/lists",
  //     "commandes/7/lists",
  //     "commandes/8/lists",
  //   ];
  //   console.log(
  //     fragmentsToCheck.some((fragment) =>
  //       window.location.href.includes(fragment)
  //     )
  //   );
  //   if (
  //     !fragmentsToCheck.some((fragment) =>
  //       window.location.href.includes(fragment)
  //     )
  //   ) {
  //     setChangeType((prev) => !prev);
  //   }
  // }, [changeType]);
  const SALES_PLATFORM = parseInt(process.env.REACT_APP_SALES_PLATFROM, 10);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<IndexLayout />}>
        <Route path="auth" element={<Login />} />
        <Route path="unauthorized" element={<UnAuthorized />} />
        <Route path="" element={<RoutLayout changeType={changeType} />}>
          <Route element={<AuthMain />}>
            <Route index element={auth.role === 1 ? <Main /> : <MainUser />} />
          </Route>
          <Route path="profile/:id" element={<Profile />} />

          {/* Routes Client */}
          <Route element={<RequireAuth allowedRoles={[26001]} />}>
            <Route element={<Meta />} path="meta" />
          </Route>
          <Route element={<RequireAuth allowedRoles={[26001]} />}>
            <Route element={<Tiktok />} path="tiktok" />
          </Route>
          <Route element={<RequireAuth allowedRoles={[35]} />}>
            <Route element={<Liste />} path="clients" />
          </Route>
          <Route element={<RequireAuth allowedRoles={[36]} />}>
            <Route path="clients/create" element={<Ajouter />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[37]} />}>
            <Route path="clients/:id/edit" element={<Modifier />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[38]} />}>
            <Route path="clients/import" element={<Import />} />
          </Route>
          {/* Routes Article */}

          <Route element={<RequireAuth allowedRoles={[26]} />}>
            <Route path="articles" element={<ListeArticle />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[27]} />}>
            <Route path="articles/create" element={<AjouterArticle />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[28]} />}>
            <Route path="articles/:id/edit" element={<ModifierArticle />} />
          </Route>
          {/* Remarque ajouter roles pour acceder page options article */}
          <Route element={<RequireAuth allowedRoles={[85]} />}>
            <Route path="articles/options" element={<OptionsArticle />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[119]} />}>
            <Route path="articles/import" element={<ImportArticle />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[34]} />}>
            {/* Routes Stock */}
            <Route path="stock/mouvement" element={<Trafic />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[120]} />}>
            {/* Routes Stock */}
            <Route path="stock/import" element={<ImportTrafic />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[80]} />}>
            {/* Routes Stock */}
            <Route path="stock/rupture" element={<AlerteStock />} />
          </Route>
          {/* Routes Fournisseur */}
          <Route element={<RequireAuth allowedRoles={[40]} />}>
            <Route
              path="fournisseurs/create"
              element={<AjouterFournisseur />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[41]} />}>
            <Route
              path="fournisseurs/:id/edit"
              element={<ModifierFournisseur />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[39]} />}>
            <Route path="fournisseurs" element={<ListeFournisseur />} />
          </Route>
          {/* Routes marques */}
          <Route element={<RequireAuth allowedRoles={[44]} />}>
            <Route path="marques/create" element={<AjouterMarque />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[45]} />}>
            <Route path="marques/:id/edit" element={<ModifierMarque />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[43]} />}>
            <Route path="marques" element={<ListeMarque />} />
          </Route>
          {/* Routes Livreurs */}
          <Route element={<RequireAuth allowedRoles={[48]} />}>
            <Route path="livreurs/create" element={<AjouterLivraison />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[47]} />}>
            <Route path="livreurs" element={<ListeLivreur />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[67]} />}>
            <Route path="livreurs/frais" element={<FraisLivraison />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[49]} />}>
            <Route path="livreurs/:id/edite" element={<ModifierLivraison />} />
          </Route>

          {/* Routes Commandes  */}

          <Route path="commandes/" element={<TypesLayout />}>
            <Route element={<RequireAuth allowedRoles={[81]} />}>
              <Route path="create/form" element={<AjouterCommande />} />
            </Route>
            {SALES_PLATFORM === 1 && (
              <Route element={<RequireAuth allowedRoles={[82]} />}>
                <Route path="create/shopify" element={<ListeShopify />} />
              </Route>
            )}
            {SALES_PLATFORM === 1 && (
              <Route element={<RequireAuth allowedRoles={[83]} />}>
                <Route path="create/youcan" element={<ListeYoucan />} />
              </Route>
            )}
            {SALES_PLATFORM === 1 && (
              <Route element={<RequireAuth allowedRoles={[83]} />}>
                <Route
                  path="create/woocommerce"
                  element={<WoocommerceListe />}
                />
              </Route>
            )}
            {SALES_PLATFORM === 1 && (
              <Route element={<RequireAuth allowedRoles={[83]} />}>
                <Route
                  path="create/lightfunnels"
                  element={<LightfunnelsList />}
                />
              </Route>
            )}

            <Route element={<RequireAuth allowedRoles={[84]} />}>
              <Route path="create/google" element={<ListeSheet />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[118]} />}>
              <Route path="create/excel" element={<ImporterCommandeExcel />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[1]} />}>
              <Route path="0/lists" element={<ListeConfirmation />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[7]} />}>
              <Route path="1/lists" element={<ListePreparation />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[14]} />}>
              <Route path="2/lists" element={<ListeDispatch />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[21]} />}>
              <Route path="3/lists" element={<ListeLivraison />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[68]} />}>
              <Route path="4/lists" element={<ListeLivree />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[66]} />}>
              <Route path="5/lists" element={<ListeRetour />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[69]} />}>
              <Route path="6/lists" element={<ListeAnnuler />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[70]} />}>
              <Route path="7/lists" element={<Paiement />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[71]} />}>
              <Route path="8/lists" element={<Retour />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[102]} />}>
              <Route path="historique/1/:id" element={<HistoriqueCmd />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[105]} />}>
              <Route path="historique/2/:id" element={<HistoriqueCmd />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[108]} />}>
              <Route path="historique/3/:id" element={<HistoriqueCmd />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[111]} />}>
              <Route path="historique/4/:id" element={<HistoriqueCmd />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[114]} />}>
              <Route path="historique/5/:id" element={<HistoriqueCmd />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[117]} />}>
              <Route path="historique/6/:id" element={<HistoriqueCmd />} />
            </Route>
          </Route>
          {/* Routes charges */}

          <Route element={<RequireAuth allowedRoles={[93]} />}>
            <Route path="charges/type" element={<TypesCharges />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[94]} />}>
            <Route path="charges" element={<Charges />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[97]} />}>
            <Route path="bilan" element={<Bilan />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[901]} />}>
            <Route
              path="status/confirmation"
              element={<StatutsConfirmation setChangeType={setChangeType} />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[902]} />}>
            <Route
              path="status/livraison"
              element={<StatutsLivraison setChangeType={setChangeType} />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[903]} />}>
            <Route
              path="status/generaux"
              element={<StatutsGeneraux setChangeType={setChangeType} />}
            />
          </Route>

          {/* Routes Boutiques */}
          <Route element={<RequireAuth allowedRoles={[63]} />}>
            <Route path="boutique/create" element={<AjouterBoutique />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[64]} />}>
            <Route path="boutiques/:id/edit" element={<ModifierBoutique />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[62]} />}>
            <Route path="boutiques" element={<ListeBoutiques />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[33]} />}>
            <Route path="produits/inventaire" element={<Inventaire />} />
          </Route>
          {/* Routes Branches */}
          <Route element={<RequireAuth allowedRoles={[25001]} />}>
            <Route path="branche/create" element={<AjouterBranche />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[25002]} />}>
            <Route
              path="branches/:id/edit"
              element={<ModifierBranche />}
            ></Route>
            <Route element={<RequireAuth allowedRoles={[25003]} />}>
              <Route path="branches" element={<ListeBranches />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[33]} />}>
              <Route path="produits/inventaire" element={<Inventaire />} />
            </Route>
          </Route>
          {/* <Route element={<RequireAuth allowedRoles={[55]} />}> */}
          {/* </Route> */}
          {/* Gestion des roles */}
          <Route element={<RequireAuth allowedRoles={[54]} />}>
            <Route path="moderateurs" element={<ListeUsers />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[55]} />}>
            <Route path="moderateurs/create" element={<AjouterModerateur />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[56]} />}>
            <Route
              path="moderateurs/:id/edite"
              element={<ModifierModerateur />}
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[59]} />}>
            <Route path="roles/create" element={<AjouterRole />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[60]} />}>
            <Route path="roles/:id/:name/edite" element={<ModifierRole />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[58]} />}>
            <Route path="roles" element={<ListeRole />} />
          </Route>
          {/* <Route element={<RequireAuth allowedRoles={[58]} />}>
            <Route path="pickup/create" element={<AjouterPickup />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[58]} />}>
            <Route path="pickup/en-collecte" element={<PickupEcollecte />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[58]} />}>
            <Route path="pickup/collecte" element={<PickupCollecte />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[58]} />}>
            <Route path="pickup/recus" element={<PickupRecus />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[58]} />}>
            <Route path="pickup/traite" element={<PickupTraiter />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[58]} />}>
            <Route path="pickup/annuler" element={<PickupAnnuler />} />
          </Route> */}
          <Route path="statistique/" element={<TypesLayout />}>
            <Route element={<RequireAuth allowedRoles={[701]} />}>
              <Route path="commande" element={<Commande />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[702]} />}>
              <Route path="produit" element={<Produit />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[703]} />}>
              <Route path="livraison" element={<Livraison />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[704]} />}>
              <Route path="confirmation" element={<Confirmation />} />
            </Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={[1301]} />}>
            <Route path="configuration" element={<Setting />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );
  return (
    <I18nextProvider i18n={i18next}>
      <TypeContext.Provider value={[changeType, setChangeType]}>
        <RouterProvider router={router} />{" "}
      </TypeContext.Provider>
    </I18nextProvider>
  );
}

export default App;
