import AffectationShop from "shared/components/AffectationShop";

const AffectationWoocommerce = ({
  domaine,
  branch,
  shops,
  handleClose,
  open,
  platforme,
  setDomaines,
  t,
}) => {
  return (
    <AffectationShop
      domaine={domaine}
      handleClose={handleClose}
      open={open}
      shops={shops}
      branch={branch}
      platforme={platforme}
      setDomaines={setDomaines}
      t={t}
    />
  );
};

export default AffectationWoocommerce;
