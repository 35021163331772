import {
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import { toast } from "react-toastify";
import {
  DeleteBrouillon,
  PostOrder,
  PostHistoricOrder,
  getCities,
  getCustomer,
  validatePhoneNumber,
  ToastStatus,
} from "setup/services";

const UpdateBrouillon = ({
  form,
  open,
  handleClose,
  setUpdate,
  shops,
  customers,
  provinces,
  cities,
  auth,
  productsDB,
  setCities,
  setChangeType,
  setSuccess,
  setOrdersValide,
  branch,
  setSelectedCmd,
  setCountSelected,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    // borderRadius: 5,
    boxShadow: 24,
    p: 2,
    height: "100%",
    overflowY: "scroll",
  };
  const {
    shop_id,
    customer_id,
    province_id,
    city_id,
    province,
    city,
    shop,
    customer,
    phone,
    email,
    address,
    ref,
    name,
    qty,
    price,
    note,
    idraft,
    cost,
    referent,
    reduction,
    stopDesk,
  } = form;
  const [shopSelected, setBoutiqueSelected] = useState(0);
  const [t] = useTranslation("global");
  const [customersSelected, setClientsSelected] = useState(0);
  const [provinceSelected, setWilayaSelected] = useState(0);
  const [citySelected, setCitySelected] = useState(0);
  const [produitSelected, setProduitSelected] = useState(0);
  const [formData, setFormData] = useState({
    customerData: customer,
    phoneData: phone,
    emailData: email,
    addressData: address,
    referentData: referent,
    noteData: note,
  });
  const [formNumber, setFormNumber] = useState({
    priceData: price,
    qtyData: qty,
    livraisonData: cost,
    reductionData: reduction,
  });
  const { priceData, qtyData, livraisonData, reductionData } = formNumber;
  const {
    customerData,
    addressData,
    phoneData,
    emailData,
    referentData,
    noteData,
  } = formData;
  const [deskSelected, setDeskSelected] = useState(0);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
    id_province: "",
    id_city: "",
    id_shop: "",
    ref: "",
  });

  useEffect(() => {
    if (provinceSelected != 0) {
      const product = provinces.filter(
        (province) => province.id_province === provinceSelected
      );
      if (product?.length > 0) {
        getCities(parseInt(provinceSelected), product[0]?.country_id).then(
          (data) => {
            setCities(data);
          }
        );
      }
    }
  }, [provinceSelected]);
  const getCitiesValue = () => {
    return cities.map((item) => {
      return <MenuItem value={item.id_city}>{item.name_city}</MenuItem>;
    });
  };
  const SendValue = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const SendValueFrais = (e) => {
    setFormNumber((prev) => ({
      ...prev,
      [e.target.id]: parseInt(e.target.value),
    }));
  };
  const getPrefixBoutique = useCallback(() => {
    if (shopSelected !== 0 && shops.length > 0) {
      const shopFilter = shops.filter((shop) => shop.id_shop == shopSelected);
      return shopFilter[0]?.prefix === "" ? null : shopFilter[0]?.prefix;
    }
    return null;
  }, [shopSelected]);
  const getPanier = (element) => {
    return [
      {
        ref: produitSelected,
        price: priceData,
        qty: qtyData,
      },
    ];
  };

  useEffect(() => {
    if (shop_id !== null) {
      const shopFilter = shops.filter((item) => item.id_shop == shop_id);
      shopFilter.length === 0
        ? setBoutiqueSelected(0)
        : setBoutiqueSelected(shop_id);
    }

    if (ref !== null) {
      const produitFilter = productsDB.filter((item) => item.ref == ref);
      produitFilter.length === 0
        ? setProduitSelected(0)
        : setProduitSelected(ref);
    }
    stopDesk == 1 ? setDeskSelected(1) : setDeskSelected(0);
    if (customersSelected !== 0) {
      getCustomer(customersSelected)
        .then((customer) => {
          setFormData({
            // clientData: customer[0].name_customer,
            phoneData: customer[0].telephone,
            emailData: customer[0].email,
            addressData: customer[0].address,
          });
          setWilayaSelected(customer[0].id_province);
          setCitySelected(customer[0].id_city);
        })
        .catch((error) => {
          // setErrorMessage(error.response.data.errors[0].msg);
        });
      setDisabledInputs(true);
    } else {
      setDisabledInputs(false);
      setFormData((prev) => ({
        ...prev,
        ["customerData"]: customer,
        ["phoneData"]: phone,
        ["emailData"]: email,
        ["addressData"]: address,
      }));
      if (customer_id !== null) {
        const customerFilter = customers.filter(
          (item) => item.id_customer == customer_id
        );
        customerFilter.length === 0
          ? setClientsSelected(0)
          : setClientsSelected(customer_id);
      }
      if (province_id !== null) {
        const provinceFilter = provinces.filter(
          (item) => item.id_province == province_id
        );
        provinceFilter.length === 0
          ? setWilayaSelected(0)
          : setWilayaSelected(province_id);
      }
      if (city_id !== null && provinceSelected !== 0) {
        const cityFilter = cities.filter((item) => item.id_city == city_id);
        cityFilter.length === 0 ? setCitySelected(0) : setCitySelected(city_id);
      }
      if (city_id !== null && provinceSelected === 0) {
        setCitySelected(city_id);
      }
      if (city_id !== null && province_id !== null && provinceSelected !== 0) {
        setCitySelected(city_id);
      }
    }
  }, [customersSelected]);
  const errorsForm = [
    t(`Vous devez sélectionner en moins un boutique dans la liste proposée.`),
    t("Vous devez sélectionner une wilaya dans la liste proposée."),
    t("Vous devez sélectionner une commune dans la liste proposée."),
    t("Le nom du client doit comporter au moins 4 caractères."),
    t("Le format du numéro de téléphone est incorrect."),
    t("Vous devez sélectionner un produit dans la liste proposée."),
  ];

  const AlertSucess = () => {
    toast.success(t("commande(s) créer avec succés"), {
      ...ToastStatus,
    });
    setSelectedCmd([]);
    setCountSelected([]);
  };
  const SendForm = () => {
    setFormErrors({
      name: "",
      phone: "",
      id_province: "",
      id_city: "",
      id_shop: "",
      ref: "",
    });
    const PhoneIsValide = validatePhoneNumber(phoneData);
    // Vérifie si boutiqueSelected, wilayaSelected, et communeSelected sont égaux à 0
    if (
      shopSelected === 0 ||
      provinceSelected === 0 ||
      citySelected === 0 ||
      produitSelected === 0 ||
      !PhoneIsValide ||
      (customersSelected === 0 && customerData === "")
    ) {
      // Met à jour l'état formErrors avec les erreurs appropriées
      setFormErrors({
        id_shop: shopSelected === 0 ? errorsForm[0] : "",
        id_province: provinceSelected === 0 ? errorsForm[1] : "",
        id_city: citySelected === 0 ? errorsForm[2] : "",
        name:
          customersSelected === 0 && customerData === "" ? errorsForm[3] : "",
        phone: PhoneIsValide === false ? errorsForm[4] : "",
        ref: produitSelected === 0 ? errorsForm[5] : "",
      });
    } else {
      AlertSucess();
      const formDataFile = new FormData();
      formDataFile.append("id_customer", customersSelected);
      formDataFile.append("id_shop", shopSelected);
      formDataFile.append("branch", branch);
      formDataFile.append("prefix", getPrefixBoutique());
      formDataFile.append("type", 0);
      formDataFile.append("methode", deskSelected);
      formDataFile.append("note", noteData);
      formDataFile.append("referent", referentData);
      formDataFile.append("delivrey", livraisonData);
      formDataFile.append("reduction", reductionData);
      // formDataFile.append("montant", CalculMontant());
      formDataFile.append("cart", JSON.stringify(getPanier()));
      formDataFile.append("name", customerData);
      formDataFile.append("phone", phoneData);
      formDataFile.append("email", emailData);
      formDataFile.append("address", addressData);
      formDataFile.append("province", provinceSelected);
      formDataFile.append("city", citySelected);
      formDataFile.append("action", 1);
      formDataFile.append("user", auth?.idUser);
      const obj = Object.fromEntries(formDataFile);
      PostOrder(obj).then(() => {
        DeleteBrouillon([idraft]).then(() => {
          setChangeType((prev) => !prev);
          setSuccess((prev) => !prev);
          setUpdate((prev) => !prev);
        });
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className="update_modal-brouillon">
      <Box
        marginTop={3}
        padding={4}
        maxWidth={800}
        sx={style}
        display={"flex"}
        flexDirection={"column"}
      >
        <FormControl
          variant="outlined"
          className="update_input-Brouillon"
          error={formErrors.id_shop === "" ? false : true}
        >
          <InputLabel id="label-methode">{t("Boutique")}</InputLabel>
          <Select
            variant="outlined"
            onChange={(e) => setBoutiqueSelected(parseInt(e.target.value))}
            label={t("Boutique")}
            value={shopSelected}
            error={formErrors.id_shop === "" ? false : true}
          >
            {shopSelected === 0 && (
              <MenuItem disabled value={0}>
                {t("Boutique")}: {shop}
              </MenuItem>
            )}
            {shops &&
              shops.length > 0 &&
              shops.map((shop) => (
                //   value={age}
                <MenuItem value={shop.id_shop}>{shop.name_shop}</MenuItem>
              ))}
          </Select>
          {formErrors.id_shop !== "" && (
            <FormHelperText>{formErrors.id_shop}</FormHelperText>
          )}
        </FormControl>

        <FormControl variant="outlined" className="update_input-Brouillon">
          <InputLabel id="label-methode">{t("Client")}</InputLabel>
          <Select
            variant="outlined"
            onChange={(e) => setClientsSelected(parseInt(e.target.value))}
            label={t("Client")}
            value={customersSelected}
          >
            {customers &&
              customers.length > 0 &&
              customers.map((item) => (
                //   value={age}
                <MenuItem value={item.id_customer}>
                  {item.name_customer}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {customersSelected === 0 && (
          <TextField
            className="update_input-Brouillon"
            id="customerData"
            value={customerData}
            label={t("Nom")}
            error={formErrors.name === "" ? false : true}
            helperText={formErrors.name === "" ? false : formErrors.name}
            onChange={(e) => SendValue(e)}
            variant="outlined"
          />
        )}
        <div className="select-d-flex" style={{ justifyContent: "center" }}>
          <TextField
            label={t("Téléphone")}
            id="phoneData"
            variant="outlined"
            onChange={(e) => SendValue(e)}
            error={formErrors.phone === "" ? false : true}
            helperText={formErrors.phone === "" ? false : formErrors.phone}
            className="update_input-Brouillon_part"
            value={phoneData}
            disabled={disabledInputs}

            // required
          />
          <TextField
            label={`${t("Email")}/ ${t("Téléphone")} 2`}
            variant="outlined"
            id="emailData"
            className="update_input-Brouillon_part"
            value={emailData}
            disabled={disabledInputs}
            onChange={(e) => SendValue(e)}

            // required
          />
        </div>
        <div
          className="select-d-flex update_display-brouillon"
          style={{ justifyContent: "center" }}
        >
          <FormControl
            variant="outlined"
            className="update_input-Brouillon_part"
            error={formErrors.id_province === "" ? false : true}
          >
            <InputLabel id="label-methode">{t("Wilaya")}</InputLabel>
            <Select
              onChange={(e) => setWilayaSelected(parseInt(e.target.value))}
              label={t("Wilaya")}
              variant="outlined"
              value={provinceSelected}
              disabled={disabledInputs}
              error={formErrors.id_province === "" ? false : true}
            >
              {provinceSelected === 0 && (
                <MenuItem disabled value={0}>
                  {t("Wilaya")}: {province}
                </MenuItem>
              )}
              {provinces &&
                provinces.length > 0 &&
                provinces.map((item) => (
                  //   value={age}
                  <MenuItem value={item.id_province}>
                    {item.name_province}
                  </MenuItem>
                ))}
            </Select>
            {formErrors.id_province !== "" && (
              <FormHelperText>{formErrors.id_province}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            variant="outlined"
            className="update_input-Brouillon_part"
            error={formErrors.id_city === "" ? false : true}
          >
            <InputLabel id="label-methode">{t("Commune")}</InputLabel>
            <Select
              onChange={(e) => setCitySelected(parseInt(e.target.value))}
              label={t("Commune")}
              variant="outlined"
              value={citySelected}
              disabled={disabledInputs}
              error={formErrors.id_city === "" ? false : true}
            >
              {citySelected === 0 && (
                <MenuItem disabled value={0}>
                  {t("Commune")}: {city}
                </MenuItem>
              )}
              {cities && cities.length > 0 && getCitiesValue()}
            </Select>
            {formErrors.id_city !== "" && (
              <FormHelperText>{formErrors.id_city}</FormHelperText>
            )}
          </FormControl>
        </div>
        <TextField
          // style={{ maxWidth: 238 }}
          label={t("Adresse")}
          variant="outlined"
          className="update_input-Brouillon"
          disabled={disabledInputs}
          id="addressData"
          value={addressData}
          onChange={(e) => SendValue(e)}
          // required
        />

        <FormControl
          variant="outlined"
          className="update_input-Brouillon"
          error={formErrors.ref === "" ? false : true}
        >
          <InputLabel id="label-methode">{t("Produit")}</InputLabel>
          <Select
            onChange={(e) => setProduitSelected(e.target.value)}
            label={t("Produit")}
            value={produitSelected}
            error={formErrors.ref === "" ? false : true}
          >
            {produitSelected === 0 && (
              <MenuItem disabled value={0}>
                {t("Produit")}: {name}
              </MenuItem>
            )}
            {productsDB &&
              productsDB.length > 0 &&
              productsDB.map((item) => (
                //   value={age}
                <MenuItem value={item.ref}>{item.name_product}</MenuItem>
              ))}
          </Select>
          {formErrors.ref !== "" && (
            <FormHelperText>{formErrors.ref}</FormHelperText>
          )}
        </FormControl>
        <div
          className="select-d-flex update_display-brouillon"
          style={{ justifyContent: "center" }}
        >
          <TextField
            label={t("Quantité")}
            variant="outlined"
            className="update_input-Brouillon_part"
            // value={nom}
            // onChange={(e) => setNom(e.target.value)}
            id="qtyData"
            value={qtyData}
            onChange={(e) => SendValueFrais(e)}
            // required
            type="number"
            inputProps={{ min: "1" }}
          />
          <TextField
            label={t("Prix unitaire")}
            variant="outlined"
            className="update_input-Brouillon_part"
            id="priceData"
            type="number"
            inputProps={{ min: "0" }}
            onChange={(e) => SendValueFrais(e)}
            value={priceData}
            // required
          />
        </div>
        <TextField
          label={t("Remarques")}
          variant="outlined"
          className="update_input-Brouillon"
          value={noteData}
          id="noteData"
          onChange={(e) => SendValue(e)}
        />
        <TextField
          label={t("Réfèrent")}
          variant="outlined"
          className="update_input-Brouillon"
          value={referentData}
          id="referentData"
          onChange={(e) => SendValue(e)}

          // required
        />
        <FormControl variant="outlined" className="update_input-Brouillon">
          <InputLabel id="label-methode">{t("Stop Desk")}</InputLabel>
          <Select
            onChange={(e) => setDeskSelected(parseInt(e.target.value))}
            label={t("Stop Desk")}
            value={deskSelected}
          >
            <MenuItem value={"1"}>{t("Oui")}</MenuItem>
            <MenuItem value={"0"}>{t("Non")}</MenuItem>
          </Select>
        </FormControl>

        <div className="panier_result">
          <div className="panier-sous_result">
            <div className="panier_result-title">{t("Sous Totale")} :</div>{" "}
            <div className="panier_result-infor padding-r">
              {" "}
              {priceData * qtyData} {auth.devise}
            </div>{" "}
          </div>

          <div className="panier-sous_result">
            <div className="panier_result-title">{t("Livraison")} :</div>{" "}
            <div className="panier_result-infor">
              {" "}
              <TextField
                id="livraisonData"
                className="label_txtField input_panier"
                type="number"
                value={livraisonData}
                onChange={(e) => SendValueFrais(e)}
                inputProps={{ min: "0" }}
              />
            </div>{" "}
          </div>
          <div className="panier-sous_result">
            <div className="panier_result-title">{t("Réduction")} :</div>{" "}
            <div className="panier_result-infor">
              {" "}
              <TextField
                onChange={(e) => SendValueFrais(e)}
                id="reductionData"
                className="label_txtField input_panier"
                type="number"
                value={reductionData}
                inputProps={{ min: "0" }}
              />
            </div>{" "}
          </div>
          <div className="panier-sous_result">
            <div className="panier_result-title">{t("Tolale")} :</div>{" "}
            <div className="panier_result-infor padding-r">
              {priceData * qtyData + livraisonData - reductionData}{" "}
              {auth.devise}
            </div>{" "}
          </div>
        </div>
        <Box padding={4} display={"flex"} justifyContent={"center"}>
          <Button
            variant="contained"
            startIcon={<MdEdit />}
            style={{ marginTop: 25 }}
            onClick={() => SendForm()}
          >
            {t("Modifier")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateBrouillon;
