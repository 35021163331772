import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CatchError,
  getAllOptionsOfProduct,
  getAllProductsWithQtyInventory,
  getErrorMessage,
} from "../../setup/services";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
export function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}
const InsertProduct = (props) => {
  const [t] = useTranslation("global");
  const { toastNotif, handleClose, setCart, IDcmd, setUpdate, auth } = props;
  const [products, setProducts] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [productssSelected, setProduitsSelected] = useState(null);
  const [alerte, setAlerte] = useState(false);
  const [open, setOpen] = React.useState(false);
  const loading = open && products.length === 0;

  useEffect(() => {
    if (productssSelected?.ref) {
      getAllOptionsOfProduct(productssSelected.ref).then((responses) => {
        const result = Object.values(
          responses.reduce((acc, item) => {
            const key = `${item.id_list}-${item.product_id}`;
            if (!acc[key]) {
              acc[key] = { ...item, options: item.name_option };
            } else {
              acc[key].options += `-${item.name_option}`;
            }
            return acc;
          }, {})
        );
        setOptions(result);
      });
    }
  }, [productssSelected]);
  const [formData, setFormData] = useState({
    qty: "",
    price: "",
  });
  const { qty, price } = formData;
  // const [formErrors, setFormErrors] = useState({
  //   qty: "",
  //   price: "",
  //   ref: "",
  // });
  useEffect(() => {
    if (alerte) {
      Swal.fire({
        title: "Attention",
        position: "center",
        icon: "warning",
        text: t("Ce produit est en rupture de stock"),
        showConfirmButton: true,
        confirmButtonColor: "#2399baff",
        timer: 4500,
      }).then((result) => {
        setAlerte(false);
      });
    }
  }, [alerte]);
  const sendProductToPanier = async (product) => {
    let error = false;
    if (
      formData.price !== "" &&
      formData.qty !== "" &&
      productssSelected !== null
    ) {
      if (!IDcmd) {
        if (
          product.inventory_min >= product.amount - qty &&
          product.receive === 1
        ) {
          setAlerte(true);
          error = true;
        } else {
          setAlerte(error);
        }
        setCart((prev) => {
          return [
            ...prev,
            {
              error: error,
              ref: product.ref,
              image: product.image,
              name: product.name_product,
              price: price,
              qty: qty,
            },
          ];
        });
      } else {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/orders/list/create`, [
            {
              ref: product.ref,
              image: product.image,
              name: product.name_product,
              price: price,
              qty: qty,
              order_id: IDcmd,
            },
          ])
          .catch(() => {
            CatchError();
          });
        toastNotif(true);
        setUpdate((prev) => !prev);
        handleClose();
      }
      setFormData({
        qty: "",
        price: "",
      });
      setOptionsSelected(null);
      setProduitsSelected(null);
    } else {
      getErrorMessage(
        t(
          "Veuillez compléter tous les champs pour les produits que vous souhaitez ajouter au panier ."
        )
      );
    }
  };
  const defaultProps = {
    options: products,
    getOptionLabel: (option) => option.name_product,
  };
  const defaultProps1 = {
    options: options,
    getOptionLabel: (option) => option.name_option,
  };
  React.useEffect(() => {
    if (productssSelected?.sale_price) {
      setFormData((prev) => ({
        ...prev,
        price: productssSelected.sale_price,
      }));
    }
  }, [productssSelected]);

  useEffect(() => {
    let active = true;
    if (!auth.branch) {
      return;
    }
    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        getAllProductsWithQtyInventory(auth.branch).then((responses) => {
          setProducts(responses);
        });
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, auth.branch]);

  useEffect(() => {
    if (!open) {
      setProducts([]);
    }
  }, [open]);
  useEffect(() => {
    if (optionsSelected?.sale_price) {
      const priceOption =
        optionsSelected.price_promos === 0
          ? optionsSelected.sale_price
          : optionsSelected.price_promos;
      setFormData((prev) => ({
        ...prev,
        price: priceOption === 0 ? productssSelected.sale_price : priceOption,
      }));
    }
  }, [optionsSelected]);
  const SendValue = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  return (
    <div
      className="insert_trafic insert_product select-d-flex"
      // onSubmit={(e) => sendSubmit(e)}
    >
      <ToastContainer style={{ position: "absolute" }} />
      <Autocomplete
        {...defaultProps}
        loading={loading}
        style={{ width: 195 }}
        className="produits-select auto-"
        id="produits"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // loadingText={loading}
        isOptionEqualToValue={(option, value) => option.ref === value.ref}
        onChange={(event, newValue) => {
          setProduitsSelected(newValue);
        }}
        value={productssSelected}
        error={true}
        renderInput={(params) => (
          <TextField
            // error={formErrors.ref === "" ? false : true}
            // helperText={formErrors.ref === "" ? false : formErrors.ref}
            {...params}
            label={t("Produits")}
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Autocomplete
        {...defaultProps1}
        style={{ width: 195 }}
        disabled={options.length > 0 ? false : true}
        className="produits-select auto-"
        id="produits"
        isOptionEqualToValue={(option, value) =>
          option.options === value.options
        }
        onChange={(event, newValue) => {
          setOptionsSelected(newValue);
        }}
        value={optionsSelected}
        renderInput={(params) => (
          <TextField {...params} label={t("Options")} variant="outlined" />
        )}
        size="small"
      />
      <TextField
        required
        // error={formErrors.price === "" ? false : true}
        // helperText={formErrors.price === "" ? false : formErrors.price}
        className=""
        size="small"
        id="price"
        label={t("Prix")}
        value={price}
        onChange={(e) => SendValue(e)}
        variant="outlined"
      />
      <TextField
        required
        // error={formErrors.qty === "" ? false : true}
        // helperText={formErrors.qty === "" ? false : formErrors.qty}
        className=""
        id="qty"
        label={t("Quantité")}
        size="small"
        value={qty}
        onChange={(e) => SendValue(e)}
        variant="outlined"
        type="number"
      />
      <Button
        onClick={() => sendProductToPanier(productssSelected)}
        variant="outlined"
        style={{ marginBottom: 0 }}
      >
        <AddIcon />
      </Button>
    </div>
  );
};
export default InsertProduct;
