import { LoadingButton } from "@mui/lab";
import React from "react";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
const ButtonLoading = () => {
  const [t] = useTranslation("global");
  return (
    <LoadingButton
      endIcon={<SendIcon />}
      loading
      loadingPosition="end"
      variant="contained"
    >
      <span>{t("Envoyer")}</span>
    </LoadingButton>
  );
};

export default ButtonLoading;
