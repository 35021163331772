import { Badge, Box, Button, IconButton, Paper, Tooltip } from "@mui/material";
import React from "react";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TbTableFilled } from "react-icons/tb";
import logo from "../../assets/images/light.jpg";
import { PiPlugChargingBold as ChargeIcon } from "react-icons/pi";
import WebhookField from "components/WebhookField";

const URL_WEBHOOK = `${process.env.REACT_APP_API_URL}/lightfunnels/webhooks/order_create`;

const HeaderLF = ({
  validateSuppression,
  handleOpenModel,
  handleOpenModelUpdate,
  countSelected,
  handleMenu,
  t,
}) => {
  // const handleAuthLF = () => {
  //   const CALLBACK_URL = `${process.env.REACT_APP_API_URL}/lightfunnels/redirect`;
  //   const CLIENT_ID = process.env.REACT_APP_LF_ID;
  //   const URL = `https://app.lightfunnels.com/admin/oauth?client_id=${CLIENT_ID}&redirect_uri=${CALLBACK_URL}&scope=products,orders&account-id=1234&state=123`;
  //   window.open(URL, "_self");
  // };
  return (
    <Box component={Paper} elevation={3} padding={2} borderRadius={2}>
      {/* <TitlePage title={"Commandes Shopify"} Icon={FaShopify} /> */}
      <Box
        display={"flex"}
        marginBottom={2}
        alignItems={"center"}
        columnGap={1}
        paddingLeft={2}
        paddingTop={2}
      >
        <img src={logo} alt="LightFunnels Logo" width={80} height={80} />
        <Box fontWeight={"700"}>
          LightFunnels API
          <Box color={"GrayText"} fontSize={12}>
            {t("LightFunnels Commandes Listes")}
          </Box>{" "}
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingRight={4}
      >
        <div className="sheet-btns">
          <WebhookField URL_WEBHOOK={URL_WEBHOOK} style={{ width: 500 }} />
          {/* <Button
            startIcon={<ChargeIcon fontSize={15} />}
            style={{ textTransform: "none" }}
            variant="contained"
            onClick={handleAuthLF}
          >
            Se connecter
          </Button>
          <Button
            startIcon={<TbTableFilled color="#01579b" fontSize={20} />}
            style={{ textTransform: "none" }}
            variant="outlined"
            // color="warning"
            onClick={handleMenu}
          >
            Stores.Domaines
          </Button> */}
        </div>
        <Box display={"flex"} columnGap={1}>
          <Box component={Paper} paddingX={1}>
            <Tooltip title={t("Modifier un brouillon")}>
              <IconButton
                onClick={handleOpenModelUpdate}
                disabled={countSelected.length !== 1}
              >
                <EditIcon color="info" fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box component={Paper} paddingX={1}>
            <Badge badgeContent={countSelected.length} color="error">
              <Tooltip title={t("Supprimer un brouillon")}>
                <IconButton
                  onClick={() => validateSuppression(countSelected)}
                  disabled={countSelected.length === 0}
                >
                  <DeleteIcon color="error" fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Badge>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderLF;
