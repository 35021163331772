const ColumnsSheet = (t) => [
  {
    title: t("Document"),
    field: "file",
  },

  {
    title: t("Boutique"),
    render: (params) => {
      return params.shop == "" ? "-" : params.shop;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Client"),

    render: (params) => {
      return params.customer == "" ? "-" : params.customer;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Téléphone"),
    render: (params) => {
      return params.phone == "" ? "-" : params.phone;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Wilaya"),
    render: (params) => {
      return params.province == "" ? "-" : params.province;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Commune"),
    render: (params) => {
      return params.city == "" ? "-" : params.city;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Adresse"),
    render: (params) => {
      return params.address == "" ? "-" : params.address;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Produit"),
    render: (params) => {
      return params.name == "" ? "-" : params.name;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Quantité"),
    render: (params) => {
      return params.qty == "" ? "-" : params.qty;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Prix unitaire"),
    render: (params) => {
      return params.price == "" ? "-" : params.price;
    },
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: t("Remarques"),
    render: (params) => {
      return params.note == "" ? "-" : params.note;
    },
    cellStyle: {
      textAlign: "center",
    },
  },

  {
    title: t("Stop Desk"),
    render: (params) => {
      return params.stopDesk === 1 ? t("Oui") : t("Non");
    },
  },
];

export default ColumnsSheet;
